@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   body {
      @apply bg-gray-100 dark:bg-gray-900 bg-opacity-50 text-gray-900 dark:text-gray-300 antialiased;
   }
   h1 {
      @apply font-extrabold text-4xl;
   }
   h2 {
      @apply font-extrabold text-2xl;
   }
   h3 {
      @apply font-bold text-xl;
   }
   h4 {
      @apply font-bold text-lg;
   }

   hr {
      @apply w-full border-0 mx-0 my-12 bg-gray-100 dark:bg-gray-700;
      height: 1px;
   }

   label {
      @apply whitespace-nowrap font-semibold opacity-70;
   }

   table thead tr th {
      @apply text-left opacity-70 text-sm;
   }

   table {
      @apply w-full;
   }
   table thead th {
      @apply p-2;
   }
   table tbody td {
      @apply p-2;
   }

   table.zebra tbody tr:nth-child(odd) {
      @apply bg-gray-50 dark:bg-gray-800;
   }
   table.zebra tbody tr:nth-child(even) {
      @apply bg-gray-200 dark:bg-gray-700;
   }

   *:focus {
      outline: none;
   }
}

@layer components {
   /* Spinner */
   .lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
   }
   .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border: 3px solid #fff;
   }
   .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
   }
   .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
   }
   .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
   }
   @keyframes lds-ring {
      0% {
         transform: rotate(0deg);
      }
      100% {
         transform: rotate(360deg);
      }
   }

   /* Colors */
   .top-48 {
      top: 48px;
   }

   .w-240 {
      width: 250px;
   }
   .w-full-240 {
      width: calc(100% - 250px);
   }
   .min-w-400 {
      min-width: 400px;
   }
   .ml-240 {
      margin-left: 250px;
   }

   .left-240 {
      left: 250px;
   }
   .-left-240 {
      left: -250px;
   }
   .text-xxs {
      font-size: 0.5rem;
   }

   .animate-ease-1 {
      transition: all 0.1s ease;
   }
   .animate-ease-2 {
      transition: all 0.2s ease;
   }
   .animate-ease-3 {
      transition: all 0.3s ease;
   }
   .animate-ease-5 {
      transition: all 0.5s ease;
   }
   .animate-cuber-5 {
      transition: all 0.4s cubic-bezier(0.3, 0.8, 0.4, 1);
   }

   /* Page */
   .page-header-container {
      @apply flex py-3 px-4 border-b bg-gray-200;
   }
   .page-header {
      @apply flex justify-between flex-1;
   }

   /* DayPickerInput */
   .DayPickerInput > input {
      @apply text-center text-sm py-2;
   }
   .DayPicker
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      @apply bg-sky-700;
   }
   .DayPicker .DayPicker-Day--today {
      @apply text-red-700;
   }

   /* Product Page */
   .product-cell {
      @apply border-r border-b px-4 whitespace-nowrap;
   }

   /* Shipment */
   .shipment-status {
      @apply font-bold text-xs border-2 text-gray-700 rounded-full border-sky-600 inline-block whitespace-nowrap px-3 py-1;
   }

   /* AMap */
   .amap-marker-label {
      @apply bg-white border-none rounded-xl px-4 py-2 opacity-75 text-center font-bold;
   }

   /* Card */
   .card {
      @apply rounded-2xl px-8 py-6 shadow-sm bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300;
   }
   .card-2 {
      @apply rounded-xl p-6 shadow-xl bg-gray-100 dark:bg-gray-800 text-gray-700 dark:text-gray-300;
   }

   /* Box */
   .box {
      @apply rounded-xl p-6 border border-gray-100 dark:border-gray-700 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300;
   }
   .box-2 {
      @apply rounded-xl p-6 border bg-gray-100 dark:border-gray-600 dark:bg-gray-800 text-gray-700 dark:text-gray-300;
   }
}
